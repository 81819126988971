'use client';

import { clsx } from 'clsx';
import { FC, useEffect, useRef } from 'react';

import './Shade.global.scss';
import styles from './Shade.module.scss';

type Props = {
  useParent?: boolean;
};

/**
 * Component in charge of displaying the highlight shade for example on buttons
 */
export const Shade: FC<Props> = ({ useParent = false }) => {
  const shade = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const shadeCurrent = useParent ? shade.current?.parentElement : shade.current;

    const handleMouseMove = (e: MouseEvent) => {
      const element = e.target as HTMLElement;
      const percentageX = (e.offsetX / element.offsetWidth) * 100;
      const percentageY = (e.offsetY / element.offsetHeight) * 100;
      shadeCurrent?.setAttribute(
        'style',
        [`--shade-mouse-x: ${percentageX}`, `--shade-mouse-y: ${percentageY}`].join(';'),
      );
    };

    if (shadeCurrent) {
      shadeCurrent.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      if (shadeCurrent) {
        shadeCurrent.removeEventListener('mousemove', handleMouseMove);
      }
    };
  }, []);

  return <span className={clsx(styles.root, 'shade')} ref={shade}></span>;
};
