import { clsx } from 'clsx';
import { FC } from 'react';

import classes from './CircularProgress.module.scss';

type Props = {
  className?: string;
};

export const CircularProgress: FC<Props> = ({ className }) => (
  <div className={clsx(className, 'circularProgress', classes.root)} role="progressbar">
    <svg className={classes.icon} aria-hidden="true" viewBox="22 22 44 44" name="circular-progress">
      <circle className={classes.circle} cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6" />
    </svg>
  </div>
);
