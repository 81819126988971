'use client';

import { clsx } from 'clsx';
import { FC, useEffect } from 'react';
import classes from './Icon.module.scss';
import { SvgIcon } from './SvgIcon.type';
import { useSvgSprite } from './SvgSprite';

type Props = SvgIcon & { viewBox: string; name: string };

export const Icon: FC<Props> = ({ children, className, viewBox, name, ...props }) => {
  const { addIcon } = useSvgSprite();

  useEffect(() => {
    addIcon(name, viewBox, children);
  }, [name, children, addIcon]);

  return (
    <svg {...props} viewBox={viewBox} aria-hidden="true" className={clsx('icon', classes.root, className)}>
      <use href={`#icon-${name}`} />
    </svg>
  );
};
