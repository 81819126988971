'use client';

import { createContext, FC, PropsWithChildren, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

type Sprite = [string, ReactNode];

const SvgSpriteContext = createContext<{
  sprites: Sprite[];
  addIcon: (name: string, viewBox: string, content: ReactNode) => void;
}>({ sprites: [], addIcon: () => {} });

export const SvgSpriteProvider: FC<PropsWithChildren> = ({ children }) => {
  const [sprites, setSprites] = useState<Sprite[]>([]);

  const addIcon = useCallback((name: string, viewBox: string, content: ReactNode) => {
    setSprites((prevSprites) => {
      if (prevSprites.find(([item, _]) => item === name)) {
        return prevSprites;
      }

      const symbolId = `icon-${name}`;

      return [
        ...prevSprites,
        [
          name,
          <symbol id={symbolId} viewBox={viewBox} key={symbolId}>
            {content}
          </symbol>,
        ],
      ];
    });
  }, []);

  const value = useMemo(() => ({ sprites, addIcon }), [sprites, addIcon]);

  return (
    <SvgSpriteContext.Provider value={value}>
      <svg display="none">
        <defs>{sprites.map((sprite) => sprite[1])}</defs>
      </svg>
      {children}
    </SvgSpriteContext.Provider>
  );
};

export const useSvgSprite = () => {
  const context = useContext(SvgSpriteContext);
  if (!context) {
    throw new Error('useSvgSprite must be used within an SvgSpriteProvider');
  }
  return context;
};
